import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const webApi = createApi({
  reducerPath: 'webApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://strapi-m8t8.onrender.com/api',
  }),
  endpoints: (builder) => ({
    // ? Query: Get All Products
    getEmployees: builder.query({
      query: () => `/employees?populate=*`,
      params: {
        populate: '*',
      },
      transformResponse: (response) => response.data,
    }),

    getNews: builder.query({
      query: () => `/news?sort=id:asc`,
      transformResponse: (response) => {
        return response.data.sort((a, b) => {
          // sort by .attributes.order, order can be null or a number
          const orderA =
            a.attributes.order !== null ? a.attributes.order : Infinity;
          const orderB =
            b.attributes.order !== null ? b.attributes.order : Infinity;
          return orderA - orderB;
        });
      },
    }),

    getSchedules: builder.query({
      query: () => `/schedules?sort=id:asc&populate=*`,
      transformResponse: (response) => response.data,
    }),

    getProjects: builder.query({
      query: () => `/projects?sort[0]=order:asc`,
      transformResponse: (response) => response.data,
    }),

    getPlans: builder.query({
      query: () => `/plans?sort=id:asc`,
      transformResponse: (response) => response.data,
    }),

    getGallery: builder.query({
      query: () => `/galeries?populate=*`,
      transformResponse: (response) => response.data,
    }),

    getMonthlyPlan: builder.query({
      query: () => `/monthly-plan`,
      transformResponse: (response) => response.data,
    }),

    getMealPlan: builder.query({
      query: () => `/meal-plan`,
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useGetNewsQuery,
  useGetSchedulesQuery,
  useGetPlansQuery,
  useGetGalleryQuery,
  useGetMonthlyPlanQuery,
  useGetMealPlanQuery,
  useGetProjectsQuery,
} = webApi;
