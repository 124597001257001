import React from 'react';
import SEO from '../../common/SEO';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import HeaderOne from '../../common/header/HeaderOne';
import Footer from '../common/Footer';
import DrJak from './DrJak';
import DrONas from './DrONas';
import DrVice from './DrVice';

const DrMain = () => {
  return (
    <>
      <SEO title="Školní družina - O Nás" />

      <HeaderOne />

      <BreadcrumbOne
        title="Školní družina
        - O Nás"
        rootUrl="/"
        parentUrl="Domů"
        currentUrl="Školní družina
        - O Nás"
      />

      <DrONas />

      <DrVice />

      <DrJak />

      <Footer />
    </>
  );
};

export default DrMain;
