import React from 'react';
import { Parallax } from 'react-scroll-parallax';

const DrJak = () => {
  return (
    <div className="eduvibe-about-three-mission edu-mission-vision-area edu-section-gap border-bottom-1 bg-color-white">
      <div className="container eduvibe-animated-shape">
        <div className="row">
          <div className="col-lg-12">
            <div className="container">
              <div className="row g-5">
                <div className="col-lg-12">
                  <div className="our-mission">
                    <div className="section-title text-start">
                      <span className="pre-title">Naše družina</span>
                      <h3 className="title">Jak to u nás vypadá</h3>
                      <p className="mt--30 mb--0">
                        Školní družina má dvě oddělení. Nachází se v prostorách
                        obou tříd. Je možné využívat interaktivní tabuli,
                        deskové a karetní hry, didaktické hry, stavebnice,
                        stolní fotbálek, stolní hokej, šipky, klavír a
                        rozšířenou knihovnu. K dispozici je dětem čtecí a
                        relaxační koutek. Děti mohou využívat velkou školní
                        zahradu, arboretum a tělocvičnu. V blízkosti se také
                        nachází les, který s dětmi pravidelně navštěvujeme.
                        Středy bývají zpravidla využívány na akce, které jsou
                        realizovány mimo družinu.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="thumbnail mt--50">
              <div className="shape-image image-1">
                <img src="/images/druzina/4.jpg" alt="Gallery Images" />
              </div>
              <div className="shape-image image-2">
                <img src="/images/druzina/5.jpg" alt="Gallery Images" />
              </div>
              <div className="shape-image image-3">
                <img src="/images/druzina/6.jpg" alt="Gallery Images" />
              </div>
              <div className="shape-image image-4">
                <img src="/images/druzina/7.jpg" alt="Gallery Images" />
              </div>
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="animated-image shape-image-1">
            <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
          </div>
          <div className="shape shape-1">
            <span className="shape-dot"></span>
          </div>
          <div className="shape shape-2">
            <span className="shape-dot"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrJak;
