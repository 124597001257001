import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../common/SectionTitle';

const DrVice = () => {
  return (
    <div className="border-bottom-1 edu-choose-us-area-one  choose-us-style-1 edu-section-gap bg-color-white">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6">
            <div className="thumbnail">
              <img
                src="/images/druzina/3.jpg"
                alt="Choose Us Images"
                style={{
                  objectFit: 'cover',
                  objectPosition: 'top',
                  height: '650px', // Set to desired height
                  width: '100%', // Set to desired width
                }}
              />
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 offset-xl-1">
            <div className="inner  mt_md--40 mt_sm--40">
              <SectionTitle
                classes="text-start"
                slogan="Více"
                title="Činnosti školní družiny:"
                animateOnce={true}
              />

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                initiallyVisible
                animateOnce={true}
              >
                <div className="line-before">
                  <ul>
                    <li>tematické odpoledne</li>
                    <li>bruslení na stadionu ve Svitavách</li>
                    <li>filmová představení v kině Vesmír ve Svitavách</li>
                    <li>návštěva muzea</li>
                    <li>Hallowenské dlabání dýní</li>
                    <li>pravidelný pobyt na školní zahradě a v tělocvičně</li>
                    <li>drakiáda</li>
                    <li>karnevalové odpoledne</li>
                    <li>
                      vycházky po okolí obce, poznávání okolní přírody (jarní
                      procházka, procházka k rybníku Rosnička, procházka na
                      hřiště do Kukle)
                    </li>
                    <li>sportovní odpoledne v areálu Březina</li>
                    <li>jumping</li>
                    <li>bowling</li>
                    <li>rukodělné a výtvarné aktivity</li>
                    <li>volnočasové aktivity</li>
                  </ul>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrVice;
