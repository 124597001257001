import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
  return (
    <p className="success-message">
      Děkujeme za zprávu, budeme Vás kontaktovat.
    </p>
  );
};

function ContactUsForm({ formStyle }) {
  const [result, setResult] = useState(false);

  return (
    <form
      className={`${formStyle}`}
      action="https://getform.io/f/25a2f8c1-64bb-4b75-9619-0dbda165f511"
      method="POST"
    >
      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            name="jmeno"
            placeholder="Jméno*"
            required
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="email"
            className="form-control form-control-lg"
            name="email"
            placeholder="Email*"
            required
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            name="telefon"
            placeholder="Telefon"
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            name="predmet"
            placeholder="Předmět"
          />
        </div>
      </div>
      <div className="col-lg-12">
        <div className="form-group">
          <textarea
            className="form-control"
            name="zprava"
            placeholder="Zpráva*"
          ></textarea>
        </div>
      </div>
      <input type="hidden" name="_gotcha"></input>
      <div className="col-lg-12">
        <button className="rn-btn edu-btn w-100" type="submit">
          <span>Odeslat zprávu</span>
          <i className="icon-arrow-right-line-right"></i>
        </button>
      </div>
      {result ? (
        <div className="form-group">
          <Result />
        </div>
      ) : null}
    </form>
  );
}
export default ContactUsForm;
