import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import Error from './pages/innerpages/Error';
import Kontakty from './pages/innerpages/Kontakty';

// Import Css Here
import './assets/scss/style.scss';
import DrMain from './pages/druzina/DrMain';
import Home from './pages/homepages/Home';
import JidelnaMain from './pages/jidelna/JidelnaMain';
import MsGalerie from './pages/ms/MsGalerie';
import MsMain from './pages/ms/MsMain';
import MsZamestnanci from './pages/ms/MsZamestnanci';
import ZsGalerie from './pages/zs/ZsGalerie';
import ZsMain from './pages/zs/ZsMain';
import ZsProjekty from './pages/zs/ZsProjekty';
import ZsSvp from './pages/zs/ZsSvp';
import ZsZamestnanci from './pages/zs/ZsZamestnanci';
import Aktualne from './pages/Aktualne';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { Admin } from './pages/admin';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route
              exact
              path={`${process.env.PUBLIC_URL + '/'}`}
              element={<Home />}
            />
            <Route exact path={`${process.env.PUBLIC_URL + '/zs'}`}>
              <Route path={'onas'} element={<ZsMain />} />
              <Route path={'fotogalerie'} element={<ZsGalerie />} />
              <Route path={'zamestnanci'} element={<ZsZamestnanci />} />
              <Route path={'projekty'} element={<ZsProjekty />} />
              <Route path={'svp'} element={<ZsSvp />} />
            </Route>

            <Route exact path={`${process.env.PUBLIC_URL + '/ms'}`}>
              <Route path={'onas'} element={<MsMain />} />
              <Route path={'fotogalerie'} element={<MsGalerie />} />
              <Route path={'zamestnanci'} element={<MsZamestnanci />} />
            </Route>

            <Route exact path={`${process.env.PUBLIC_URL + '/dr'}`}>
              <Route path={'onas'} element={<DrMain />} />
            </Route>

            <Route
              exact
              path={`${process.env.PUBLIC_URL + '/kontakty'}`}
              element={<Kontakty />}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL + '/jidelna'}`}
              element={<JidelnaMain />}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL + '/aktuality'}`}
              element={<Aktualne />}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL + '/admin'}`}
              element={<Admin />}
            />

            <Route path="*" element={<Error />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </Provider>
  );
}

export default App;
