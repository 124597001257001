import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

import logoEu from '../../files/eu.jpg';
import plakatEu from '../../files/plakat_doucovani.jpg';
import { useGetProjectsQuery } from '../../redux/slice/api.slice';

import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const ZsProjekty = () => {
  <SEO title="Základní škola - Projekty" />;

  const { data: projects = [], isSuccess } = useGetProjectsQuery();

  return (
    <>
      <SEO title="Projekty" />
      <Layout>
        <BreadcrumbOne
          title="Projekty"
          rootUrl="/"
          parentUrl="Domů"
          currentUrl="Projekty"
        />
        <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="content">
                {isSuccess &&
                  projects.map((item) => (
                    <>
                      <section className="mt--50" key={item.id}>
                        <ReactMarkdown linkTarget="_blank">
                          {item.attributes.content}
                        </ReactMarkdown>
                      </section>

                      <hr />
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default ZsProjekty;
