import React from 'react';

const EventTwo = ({ data, shade, shadow, classes, apiImageRoute }) => {
  shadow = 'enable';

  return (
    <div
      className={`edu-event event-grid-1 radius-small ${
        classes ? classes : ''
      } ${shade === 'enable' ? 'bg-shade' : ''} ${
        shadow === 'enable' ? 'eduvibe-event-two-shadow' : ''
      }`}
    >
      <div className="inner">
        <div className="thumbnail">
          <a
            href={data.zoneramaLink}
            target="_blank"
            rel="noopenner noreferrer"
          >
            <img
              width={400}
              height={220}
              src={
                apiImageRoute
                  ? apiImageRoute
                  : `${process.env.PUBLIC_URL}/images/galerie/${data.image}`
              }
              alt="Event Thumb"
              style={{
                width: '400px',
                height: '220px',
                objectFit: 'cover',
              }}
            />
          </a>
          <div className="top-position status-group left-top">
            <span className="eduvibe-status status-06">{data.startDate}</span>
          </div>
        </div>
        <div className="content">
          <h5 className="title">
            <a
              href={data.zoneramaLink}
              target="_blank"
              rel="noopenner noreferrer"
            >
              {data.title}
            </a>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default EventTwo;
