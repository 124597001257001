import React from 'react';

const DrONas = () => {
  return (
    <div className="border-bottom-1 bg-color-white eduvibe-home-four-about edu-about-area about-style-2 edu-section-gap bg-color-white">
      <div className="container eduvibe-animated-shape">
        <div className="row row--50">
          <div className="col-lg-6">
            <div className="about-image-gallery">
              <div className="eduvibe-about-1-img-wrapper">
                <img
                  className="image-1"
                  src="/images/druzina/1.jpg"
                  alt="About Images"
                />
                <span className="eduvibe-about-blur">
                  <img
                    src="/images/about/about-07/about-blur.png"
                    alt="About Blur"
                  />
                </span>
              </div>
              <div className="circle-image-wrapper">
                <img
                  className="image-2"
                  src="/images/druzina/circle.png"
                  alt="About Images"
                />
                <div className="circle-image">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="inner mt_md--40 mt_sm--40">
              <div className="section-title text-start">
                <span className="pre-title">O Nás</span>
                <h3 className="title">Představení Školní družiny</h3>
              </div>
              <p className="description mt--40 mt_md--20 mt_sm--20">
                Školní družina základní školy v Javorníku je nedílnou součástí
                vzdělávacího procesu. Cílem je, aby děti měly po skončení
                vyučování dostatek prostoru pro vybití energie a k pohybovým,
                výtvarným či relaxačním aktivitám, které po dopoledním vyučování
                potřebují.
              </p>

              <p className="description mt--40 mt_md--20 mt_sm--20">
                Provozní doba školní družiny je ráno od 6:40 do 7:50, odpolední
                družina je od 11:40 do 16:00, ve středu je družina prodloužená
                do 16:30. Měsíční poplatek je 100 Kč.
              </p>
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image shape-image-1">
            <img src="/images/shapes/shape-11-05.png" alt="Shape Thumb" />
          </div>
          <div className="shape-image shape-image-2">
            <img src="/images/shapes/shape-08-01.png" alt="Shape Thumb" />
          </div>
          <div className="shape-image shape-image-3">
            <img src="/images/shapes/shape-30.png" alt="Shape Thumb" />
          </div>
          <div className="shape shape-1">
            <span className="shape-dot"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrONas;
