import React from 'react';
import { Element } from 'react-scroll';

import jidelniListek from '../../files/jidelni_listek.pdf';
import { useGetMealPlanQuery } from '../../redux/slice/api.slice';

const JidelnaVice = () => {
  const { data: mealPlan } = useGetMealPlanQuery();

  return (
    <Element
      name="about-us"
      className="edu-about-area about-style-1 edu-section-gap bg-color-white"
    >
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6">
            <div className="about-image-gallery">
              <img
                className="image-1"
                src="/images/jidelna/1.webp"
                alt="About Main Thumb"
              />
              <div className="image-2">
                <img
                  src="/images/jidelna/2.webp"
                  alt="About Parallax Thumb"
                  style={{
                    width: '70%',
                    height: '70%',
                  }}
                />
              </div>

              <div className="shape-image shape-image-1">
                <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="inner">
              <div className="section-title">
                <h3 className="title">Jídelna</h3>
              </div>
              <p className="description">
                Jídelna má moderně vybavenou kuchyni a kapacitu 60 strávníků.
                Stravování využívají děti, žáci a zaměstnanci ZŠ a MŠ, případně
                cizí strávníci.
              </p>

              <div className="read-more-btn">
                <a
                  className="edu-btn"
                  href={mealPlan ? mealPlan.attributes.url : ''}
                  target="_blank"
                  rel="noopenner noreferrer"
                >
                  Jídelní lístek<i className="icon-arrow-right-line-right"></i>
                </a>
              </div>
              <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
                <img src="/images/shapes/shape-07.png" alt="Shape Thumb" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default JidelnaVice;
