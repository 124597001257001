import React from 'react';

import svpMs from '../../files/svp_pv.pdf';

const MsONas = () => {
  return (
    <div className="border-bottom-1 bg-color-white eduvibe-home-four-about edu-about-area about-style-2 edu-section-gap bg-color-white">
      <div className="container eduvibe-animated-shape">
        <div className="row row--50">
          <div className="col-lg-6">
            <div className="about-image-gallery">
              <div className="eduvibe-about-1-img-wrapper">
                <img
                  className="image-1"
                  src="/images/materska-skola/3.webp"
                  alt="About Images"
                />
                <span className="eduvibe-about-blur">
                  <img
                    src="/images/about/about-07/about-blur.png"
                    alt="About Blur"
                  />
                </span>
              </div>
              <div className="circle-image-wrapper">
                <img
                  className="image-2"
                  src="/images/materska-skola/4.webp"
                  alt="About Images"
                  style={{ width: '250px', height: '250px' }}
                />
                <div className="circle-image">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="inner mt_md--40 mt_sm--40">
              <div className="section-title text-start">
                <span className="pre-title">O Nás</span>
                <h3 className="title">Představení Mateřské školy</h3>
              </div>
              <p className="description mt--40 mt_md--20 mt_sm--20">
                Naše nově zrekonstruovaná MŠ zahájila provoz v roce 2008 a je
                součástí Základní školy. Budova MŠ s přilehlou školní zahradou
                se nachází v klidné části obce v objektu Společenského a
                vzdělávacího centra. V blízkosti školky jsou lesy, pole, louky i
                sportoviště, které hojně využíváme.
              </p>

              <p className="description mt--40 mt_md--20 mt_sm--20">
                Jsme jednotřídní MŠ s celodenním provozem a kapacitou 24 dětí.
              </p>
              <div className="read-more-btn mt--40">
                <a
                  className="edu-btn"
                  href={svpMs}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Školní vzdělávací program MŠ Javorník
                  <i className="icon-arrow-right-line-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image shape-image-1">
            <img src="/images/shapes/shape-11-05.png" alt="Shape Thumb" />
          </div>
          <div className="shape-image shape-image-2">
            <img src="/images/shapes/shape-08-01.png" alt="Shape Thumb" />
          </div>
          <div className="shape-image shape-image-3">
            <img src="/images/shapes/shape-30.png" alt="Shape Thumb" />
          </div>
          <div className="shape shape-1">
            <span className="shape-dot"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MsONas;
