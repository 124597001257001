import React from 'react';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import BannerFour from '../../components/banner/BannerFour';

const Home = () => {
  return (
    <>
      <SEO title="Hlavní stránka" />

      <Layout>
        <BannerFour />
      </Layout>
    </>
  );
};

export default Home;
