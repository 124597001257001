import { configureStore } from '@reduxjs/toolkit';
import { webApi } from './slice/api.slice';

export const store = configureStore({
  reducer: {
    [webApi.reducerPath]: webApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([webApi.middleware]),
});
