import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import InstructorOne from '../../components/instructor/InstructorOne';
import { useGetEmployeesQuery } from '../../redux/slice/api.slice';

const ZsZamestnanci = () => {
  <SEO title="Základní škola - Zaměstnanci" />;

  const { data: employees = [], isSuccess } = useGetEmployeesQuery();

  return (
    <>
      <SEO title="Základní škola - Zaměstnanci" />
      <Layout>
        <BreadcrumbOne
          title="Základní škola - Zaměstnanci"
          rootUrl="/"
          parentUrl="Domů"
          currentUrl="Základní škola - Zaměstnanci"
        />
        <div className="edu-elements-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="pre-title">
                    Personální obsazení základní školy:
                  </span>
                  <h3 className="title">Zaměstnanci</h3>
                </div>
              </div>
            </div>
            <div className="row row--20">
              {isSuccess &&
                employees
                  .filter((item) => item.attributes.type === 'zs')
                  .sort((a, b) => {
                    const orderA =
                      a.attributes.order !== null
                        ? a.attributes.order
                        : Infinity;
                    const orderB =
                      b.attributes.order !== null
                        ? b.attributes.order
                        : Infinity;
                    return orderA - orderB;
                  })
                  .map((item) => (
                    <div
                      className="col-lg-3 col-md-6 col-sm-6 col-12 mt--45"
                      key={item.id}
                    >
                      <InstructorOne data={item} />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ZsZamestnanci;
