import React from 'react';
import { Link } from 'react-router-dom';

import informaceProRodice from '../../files/informace_pro_rodice.pdf';

import skolniRadMS from '../../files/skolni_rad_ms.pdf';

import {
  useGetMonthlyPlanQuery,
  useGetPlansQuery,
  useGetSchedulesQuery,
} from '../../redux/slice/api.slice';

const Nav = () => {
  const { data: schedules = [] } = useGetSchedulesQuery();
  const { data: plans = [] } = useGetPlansQuery();
  const { data: monthlyPlan } = useGetMonthlyPlanQuery();

  return (
    <ul className="mainmenu">
      <li>
        <Link to="/aktuality">Aktuálně</Link>
      </li>

      <li className="has-droupdown">
        <Link to="#">Základní škola</Link>
        <ul className="submenu">
          <li>
            <Link to="/zs/onas">O Nás</Link>
          </li>
          <li className="has-droupdown">
            <Link to="#">Rozvrh hodin</Link>
            <ul className="submenu">
              {schedules.map((item) => (
                <li>
                  <a
                    href={item.attributes.url}
                    target="_blank"
                    rel="noopenner noreferrer"
                  >
                    {item.attributes.text}
                  </a>
                </li>
              ))}
            </ul>
          </li>
          <li>
            <Link to="/zs/projekty">Projekty</Link>
          </li>
          <li>
            <Link to="/zs/svp">ŠVP, školní řád</Link>
          </li>
          <li>
            <Link to="/zs/zamestnanci">Zaměstnanci</Link>
          </li>

          <li className="has-droupdown">
            <Link to="#">Týdenní plány</Link>
            <ul className="submenu">
              {plans.map((item) => (
                <li>
                  <a
                    href={item.attributes.url}
                    target="_blank"
                    rel="noopenner noreferrer"
                  >
                    {item.attributes.text}
                  </a>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </li>

      <li className="has-droupdown">
        <Link to="#">Mateřská škola</Link>
        <ul className="submenu">
          <li>
            <Link to="/ms/onas">O Nás</Link>
          </li>
          <li>
            <a
              href={informaceProRodice}
              target="_blank"
              rel="norefferer noreferrer"
            >
              Informace pro rodiče
            </a>
          </li>
          <li>
            <a href={skolniRadMS} target="_blank" rel="norefferer noreferrer">
              Školní řád
            </a>
          </li>
          <li>
            <Link to="/ms/zamestnanci">Zaměstnanci</Link>
          </li>
          <li>
            <a
              href={monthlyPlan ? monthlyPlan.attributes.url : ''}
              target="_blank"
              rel="norefferer noreferrer"
            >
              Měsíční plán
            </a>
          </li>
        </ul>
      </li>

      <li className="has-droupdown">
        <Link to="#">Školní Družina</Link>
        <ul className="submenu">
          <li>
            <Link to="/dr/onas">O Nás</Link>
          </li>
        </ul>
      </li>

      <li className="has-droupdown">
        <Link to="#">Fotogalerie</Link>
        <ul className="submenu">
          <li>
            <Link to="/zs/fotogalerie">Základní škola</Link>
          </li>
          <li>
            <Link to="/ms/fotogalerie">Mateřská škola</Link>
          </li>
        </ul>
      </li>

      <li>
        <Link to="/jidelna">Jídelna</Link>
      </li>

      <li>
        <Link to="/kontakty">Kontakty</Link>
      </li>
    </ul>
  );
};
export default Nav;
