import React from 'react';
import SEO from '../../common/SEO';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import HeaderOne from '../../common/header/HeaderOne';
import Footer from '../common/Footer';
import ZsJak from './ZsJak';
import ZsONas from './ZsONas';
import ZsVice from './ZsVice';

const ZsMain = () => {
  return (
    <>
      <SEO title="Základní škola - O Nás" />

      <HeaderOne />

      <BreadcrumbOne
        title="Základní škola - O Nás"
        rootUrl="/"
        parentUrl="Domů"
        currentUrl="Základní škola - O Nás"
      />

      <ZsONas />

      <ZsVice />

      <ZsJak />

      <Footer />
    </>
  );
};

export default ZsMain;
