import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import EventTwo from '../../components/event/EventTwo';
import EventData from '../../data/event/ZsGalerieData.json';
import { useGetGalleryQuery } from '../../redux/slice/api.slice';

const ZsGalerie = () => {
  <SEO title="Základní škola - Galerie" />;

  const { data: galeries = [] } = useGetGalleryQuery();

  const zsGaleries = galeries.filter((item) => item.attributes.type === 'zs');

  const [getAllItems] = useState(EventData);
  const [dataVisibleCount, setDataVisibleCount] = useState(9);
  const [dataIncrement] = useState(6);
  const [noMorePost, setNoMorePost] = useState(
    dataVisibleCount >= getAllItems.length ? true : false,
  );

  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    setVisibleItems(
      getAllItems.filter(
        (item) => item.id <= dataVisibleCount - zsGaleries.length,
      ),
    );

    if (dataVisibleCount >= getAllItems.length) {
      setNoMorePost(true);
    }
  }, [dataVisibleCount, getAllItems, galeries]);

  const handleLoadMoreBtn = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount >= getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
    }
  };

  return (
    <>
      <SEO title="Základní škola - Fotogalerie" />
      <Layout>
        <BreadcrumbOne
          title="Základní škola - Fotogalerie"
          rootUrl="/"
          parentUrl="Domů"
          currentUrl="Základní škola - Fotogalerie"
        />
        <div className="edu-elements-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="row g-5">
                  {zsGaleries.map((item) => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                      key={item.id}
                    >
                      <EventTwo
                        apiImageRoute={
                          item.attributes.thumbnail.data.attributes.url
                        }
                        data={{
                          id: item.attributes.id,
                          title: item.attributes.title,
                          zoneramaLink: item.attributes.zoneramaURL,
                          startDate: item.attributes.date,
                        }}
                      />
                    </div>
                  ))}
                  {visibleItems.map((item) => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                      key={item.id}
                    >
                      <EventTwo data={item} />
                    </div>
                  ))}
                </div>
                {/* Load More Button */}

                {!noMorePost && (
                  <div className="row text-center mt--60">
                    <div className="col-lg-12">
                      <button
                        className="edu-btn"
                        onClick={handleLoadMoreBtn}
                        disabled={noMorePost ? 'disabled' : null}
                      >
                        <span>Zobrazit další</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ZsGalerie;
