import React from 'react';
import { Parallax } from 'react-scroll-parallax';

const ZsJak = () => {
  return (
    <div className="eduvibe-about-three-mission edu-mission-vision-area edu-section-gap border-bottom-1 bg-color-white">
      <div className="container eduvibe-animated-shape">
        <div className="row">
          <div className="col-lg-12">
            <div className="container">
              <div className="row g-5">
                <div className="col-lg-12">
                  <div className="our-mission">
                    <div className="section-title text-start">
                      <span className="pre-title">Naše škola</span>
                      <h3 className="title">Jak to u nás vypadá</h3>
                      <p className="mt--30 mb--0">
                        Ve škole máme dvě prostorné a vybavené třídy
                        (interaktivní tabule, stolní počítače k výuce
                        informatiky, velké množství karetních a deskových her,
                        různorodé hračky nebo relaxační koutky se sedacími vaky
                        a knihami). Výuku i volný čas trávíme také v tělocvičně
                        nebo na rozlehlé školní zahradě.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="thumbnail mt--50">
              <div className="shape-image image-1">
                <img src="/images/zakladni-skola/5.webp" alt="Gallery Images" />
              </div>
              <div className="shape-image image-2">
                <img src="/images/zakladni-skola/8.webp" alt="Gallery Images" />
              </div>
              <div className="shape-image image-3">
                <img
                  src="/images/zakladni-skola/10.webp"
                  alt="Gallery Images"
                />
              </div>
              <div className="shape-image image-4">
                <img
                  src="/images/zakladni-skola/12.webp"
                  alt="Gallery Images"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="animated-image shape-image-1">
            <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
          </div>
          <div className="shape shape-1">
            <span className="shape-dot"></span>
          </div>
          <div className="shape shape-2">
            <span className="shape-dot"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZsJak;
