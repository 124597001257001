import React from 'react';

const InstructorOne = ({ data }) => {
  return (
    <div className="edu-instructor-grid edu-instructor-1">
      <div className="edu-instructor">
        <div className="inner">
          <div
            className="thumbnail"
            style={{
              backgroundImage: `url(${data.attributes.avatar.data.attributes.formats.small.url})`,
              width: '230px',
              height: '230px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: '50%',
            }}
          >
            <a href={`mailto:${data.attributes.email}`}>
              <div></div>
            </a>
          </div>
          {data.attributes.email && (
            <div className="team-share-info">
              <a className="mail-icon" href={`mailto:${data.attributes.email}`}>
                <i className="icon-mail-line-2"></i>
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="edu-instructor-info">
        <h5 className="title">{data.attributes.name}</h5>
        <span className="desc">{data.attributes.description}</span>
      </div>
    </div>
  );
};
export default InstructorOne;
