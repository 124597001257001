import React from 'react';
import { Parallax } from 'react-scroll-parallax';

const MsJak = () => {
  return (
    <div className="eduvibe-about-three-mission edu-mission-vision-area edu-section-gap border-bottom-1 bg-color-white">
      <div className="container eduvibe-animated-shape">
        <div className="row">
          <div className="col-lg-12">
            <div className="container">
              <div className="row g-5">
                <div className="col-lg-12">
                  <div className="our-mission">
                    <div className="section-title text-start">
                      <span className="pre-title">Naše školka</span>
                      <h3 className="title">Jak to u nás vypadá</h3>
                      <p className="mt--30 mb--0">
                        Pracujeme podle ŠVP „Rok v naší školičce“, který vychází
                        z RVP PV a je průběžně aktualizován (dodatky k dispozici
                        v MŠ).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="thumbnail mt--50">
              <div className="shape-image image-1">
                <img
                  src="/images/materska-skola/11.webp"
                  alt="Gallery Images"
                />
              </div>
              <div className="shape-image image-2">
                <img
                  src="/images/materska-skola/12.webp"
                  alt="Gallery Images"
                />
              </div>
              <div className="shape-image image-3">
                <img src="/images/materska-skola/1.webp" alt="Gallery Images" />
              </div>
              <div className="shape-image image-4">
                <img src="/images/materska-skola/2.webp" alt="Gallery Images" />
              </div>
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="animated-image shape-image-1">
            <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
          </div>
          <div className="shape shape-1">
            <span className="shape-dot"></span>
          </div>
          <div className="shape shape-2">
            <span className="shape-dot"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MsJak;
