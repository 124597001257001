import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactUsForm from '../../components/contact/ContactUsForm';

const Kontakty = () => {
  return (
    <>
      <SEO title="Kontakty" />
      <Layout>
        <BreadcrumbOne
          title="Kontakty"
          rootUrl="/"
          parentUrl="Domů"
          currentUrl="Kontakty"
        />
        <div className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white">
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="pre-title">Máte dotaz?</span>
                  <h3 className="title">Zde nás můžete kontaktovat</h3>
                </div>
              </div>
            </div>
            <div className="row g-5 mt--20">
              <div className="col-lg-6">
                <div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                  <div className="row g-5">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 location">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-map-pin-line"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Základní škola</h6>
                            <p>Javorník 61</p>
                            <p>568 02 Svitavy</p>
                            <p>
                              <a href="tel:725915056">Telefon: 725 915 056</a>
                            </p>
                            <p>IČO: 75016001</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 location">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-map-pin-line"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Mateřská škola</h6>
                            <p>Javorník 26</p>
                            <p>568 02 Svitavy</p>
                            <p>
                              <a href="tel:725404979">Telefon: 725 404 979</a>
                            </p>
                            <p>
                              <a href="tel:461531290">Telefon: 461 531 290</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 location">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-map-pin-line"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Jídelna</h6>
                            <p>Javorník 26</p>
                            <p>568 02 Svitavy</p>
                            <p>
                              <a href="tel:461311376">Telefon: 461 311 376</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 location">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-mail-open-line"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Email</h6>
                            <p>
                              <a href="mailto:skola.javornik@seznam.cz">
                                skola.javornik@seznam.cz
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <ContactUsForm formStyle="rnt-contact-form rwt-dynamic-form row" />
              </div>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
              <div className="shape-image shape-image-1">
                <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-2">
                <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-3">
                <img src="/images/shapes/shape-15.png" alt="Shape Thumb" />
              </div>
            </div>
          </div>
        </div>

        <div className="edu-contact-map-area edu-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="google-map alignwide">
                  <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4293.661925014684!2d16.424280426136935!3d49.78235725903179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470d8c7675896afd%3A0x5c3669837cc698d6!2zWsOha2xhZG7DrSDFoWtvbGEgYSBNYXRlxZlza8OhIMWha29sYSBKYXZvcm7DrWssIG9rcmVzIFN2aXRhdnk!5e1!3m2!1scs!2scz!4v1691690278977!5m2!1scs!2scz"
                    height="500"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Kontakty;
