import React from 'react';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import JidelnaVice from './JidelnaVice';

const JidelnaMain = () => {
  return (
    <>
      <SEO title="Jídelna" />
      <Layout>
        <BreadcrumbOne
          title="Jídelna"
          rootUrl="/"
          parentUrl="Domů"
          currentUrl="Jídelna"
        />

        <JidelnaVice />

        <div className="edu-event-details-area edu-event-details bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h3 className="title">Platby za stravné</h3>
                  <p>
                    Na základě vyhlášky 463/2011 Sb., kterou se mění vyhláška č.
                    107/2005 Sb., o školním stravování, ve znění vyhlášky č.
                    107/2008 Sb. upravujeme ceny stravného takto:
                  </p>
                  <h5>Ceny za stravné platné od 1. 4. 2022:</h5>

                  <h6>Strávníci do 6 let:</h6>

                  <b>CELODENNÍ STRAVOVÁNÍ</b>
                  <ul className="list-item mb--20">
                    <li>Přesnídávka 8,- Kč</li>
                    <li>Oběd 26,- Kč</li>
                    <li>Svačina 8,- Kč</li>
                    <li>
                      Cena celkem <b>42,- Kč</b>
                    </li>
                    <li>Nápoje /pitný režim/ jsou součástí stravování.</li>
                  </ul>

                  <b>POLODENNÍ STRAVOVÁNÍ – 4 HODINY DENNĚ</b>
                  <ul className="list-item mb--20">
                    <li>Přesnídávka 8,- Kč</li>
                    <li>Oběd 26,- Kč</li>
                    <li>
                      Cena celkem <b>34,- Kč</b>
                    </li>
                    <li>Nápoje /pitný režim/ jsou součástí stravování.</li>
                  </ul>

                  <h6>Strávníci 7-10 let:</h6>
                  <ul className="list-item mb--20">
                    <li>Přesnídávka 9,- Kč</li>
                    <li>Oběd 26,- Kč</li>
                    <li>Svačina 8,- Kč</li>
                    <li>
                      Cena celkem <b>45,- Kč</b>
                    </li>
                    <li>Nápoje /pitný režim/ jsou součástí stravování.</li>
                  </ul>

                  <h6>Strávníci 11-14 let:</h6>
                  <ul className="list-item mb--20">
                    <li>Oběd 30,- Kč</li>
                  </ul>

                  <h6>Cizí strávníci:</h6>
                  <ul className="list-item mb--20">
                    <li>Oběd 58,- Kč</li>
                  </ul>

                  <p>
                    Stravné a školné na následující kalendářní měsíc lze platit
                    v hotovosti, bezhotovostně převodem z účtu nebo
                    prostřednictvím inkasa do 20. dne v měsíci - na Vašem účtu
                    je třeba zadat souhlas s inkasní platbou ve prospěch účtu
                    číslo 1284806309/0800. Platba se provede automaticky a bude
                    odpovídat počtu obědů v měsíci (+školné).
                  </p>
                  <p>
                    <b>
                      Obědy je nutno odhlásit nejpozději jeden den předem do 12
                      hod.
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default JidelnaMain;
