import React from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import BreadcrumbOne from '../common/breadcrumb/BreadcrumbOne';
import { useGetNewsQuery } from '../redux/slice/api.slice';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const Aktualne = () => {
  const { data: news = [] } = useGetNewsQuery();

  return (
    <>
      <SEO title="Aktuálně" />
      <Layout>
        <BreadcrumbOne
          title="Aktuálně"
          rootUrl="/"
          parentUrl="Domů"
          currentUrl="Aktuálně"
        />
        <div className="bg-color-white">
          <div className="container">
            <div className="row mt--50 mb--50">
              {news.map((item) => (
                <>
                  <section>
                    <h5 className="title">{item.attributes.title}</h5>
                    <ReactMarkdown linkTarget="_blank">
                      {item.attributes.content}
                    </ReactMarkdown>
                  </section>

                  <hr />
                </>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Aktualne;
