import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

import svpZS from '../../files/svp_zv.pdf';
import skolniRadZs from '../../files/skolni_rad_zs.pdf';

const ZsSvp = () => {
  return (
    <>
      <SEO title="Základní škola - ŠVP, školní řád" />
      <Layout>
        <BreadcrumbOne
          title="ŠVP, školní řád
          "
          rootUrl="/"
          parentUrl="Domů"
          currentUrl="ŠVP, školní řád
          "
        />
        <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="content d-flex flex-column gap-5">
                <div className="d-flex flex-column justify-items-center">
                  <a
                    className="d-flex align-items-center"
                    href={svpZS}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Školní vzdělávací program Pohodový Javorníček
                    <i className="icon-arrow-right-line-right"></i>
                  </a>
                  (písemné dodatky jsou k dispozici v budově ZŠ)
                </div>

                <div className="pricing-btn">
                  <a
                    href={skolniRadZs}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Školní řád
                    <i className="icon-arrow-right-line-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default ZsSvp;
