import React from 'react';

const ZsONas = () => {
  return (
    <div className="border-bottom-1 bg-color-white eduvibe-home-four-about edu-about-area about-style-2 edu-section-gap bg-color-white">
      <div className="container eduvibe-animated-shape">
        <div className="row row--50">
          <div className="col-lg-6">
            <div className="about-image-gallery">
              <div className="eduvibe-about-1-img-wrapper">
                <img
                  className="image-1"
                  src="/images/zakladni-skola/1.webp"
                  alt="About Images"
                />
                <span className="eduvibe-about-blur">
                  <img
                    src="/images/about/about-07/about-blur.png"
                    alt="About Blur"
                  />
                </span>
              </div>
              <div className="circle-image-wrapper">
                <img
                  className="image-2"
                  src="/images/zakladni-skola/3.webp"
                  alt="About Images"
                  style={{ width: '250px', height: '250px' }}
                />
                <div className="circle-image">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="inner mt_md--40 mt_sm--40">
              <div className="section-title text-start">
                <span className="pre-title">O Nás</span>
                <h3 className="title">Představení školy</h3>
              </div>
              <p className="description mt--40 mt_md--20 mt_sm--20">
                Základní škola Javorník je malotřídní škola ležící nedaleko
                Svitav. Snažíme se o otevřenou a partnerskou komunikaci s dětmi
                i jejich rodiči. Dětem poskytujeme podnětné prostředí a snažíme
                se jim vytvářet podmínky, které umožňují co nejlepší rozvoj
                všech složek jejich osobnosti a současně zohledňují jejich
                potřeby. Ve výuce se nezaměřujeme pouze na faktické znalosti,
                ale snažíme se propojovat výuku s praktickým využitím v životě.
                Škola je zapojena do projektu Ovoce a mléko do škol.
              </p>
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image shape-image-1">
            <img src="/images/shapes/shape-11-05.png" alt="Shape Thumb" />
          </div>
          <div className="shape-image shape-image-2">
            <img src="/images/shapes/shape-08-01.png" alt="Shape Thumb" />
          </div>
          <div className="shape-image shape-image-3">
            <img src="/images/shapes/shape-30.png" alt="Shape Thumb" />
          </div>
          <div className="shape shape-1">
            <span className="shape-dot"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZsONas;
