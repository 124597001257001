import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTopButton from './ScrollTopButton';

const Footer = () => {
  return (
    <>
      <footer className="eduvibe-footer-one edu-footer footer-style-default">
        <div className="footer-top">
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="edu-footer-widget">
                  <div className="logo">
                    <Link to={process.env.PUBLIC_URL + '/'}>
                      <img
                        className="logo-light"
                        src="/images/logo/logo-white.png"
                        alt="Footer Logo"
                      />
                    </Link>
                  </div>
                  <p className="description">
                    Základní škola Javorník je malotřídní škola ležící nedaleko
                    Svitav. Snažíme se o otevřenou a partnerskou komunikaci s
                    dětmi i jejich rodiči.
                  </p>
                  <ul className="social-share">
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer no"
                        href="https://www.facebook.com/people/Z%C5%A0-a-M%C5%A0-Javorn%C3%ADk/100051851865278/"
                      >
                        <i className="icon-Fb"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget explore-widget">
                  <h5 className="widget-title">Navigace</h5>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      <li>
                        <Link to="/">
                          <i className="icon-Double-arrow"></i>Hlavní stránka
                        </Link>
                      </li>
                      <li>
                        <Link to="/aktuality">
                          <i className="icon-Double-arrow"></i>Aktuality
                        </Link>
                      </li>
                      <li>
                        <Link to="/zs/onas">
                          <i className="icon-Double-arrow"></i>Základní škola
                        </Link>
                      </li>
                      <li>
                        <Link to="/ms/onas">
                          <i className="icon-Double-arrow"></i>Mateřská škola
                        </Link>
                      </li>
                      <li>
                        <Link to="/dr/onas">
                          <i className="icon-Double-arrow"></i>Školní družina
                        </Link>
                      </li>
                      <li>
                        <Link to="/jidelna">
                          <i className="icon-Double-arrow"></i>Jídelna
                        </Link>
                      </li>
                      <li>
                        <Link to="/kontakty">
                          <i className="icon-Double-arrow"></i>Kontakty
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget">
                  <h5 className="widget-title">Kontaktní informace</h5>
                  <div className="inner">
                    <div className="widget-information footer-link link-hover">
                      <ul className="information-list">
                        <li>
                          <i className="icon-map-pin-line"></i>
                          Javorník 61, 568 02 Svitavy
                        </li>
                        <li>
                          <i className="icon-phone-fill"></i>
                          <a href="tel: +420 725 915 056">725 915 056</a>
                        </li>
                        <li>
                          <i className="icon-phone-fill"></i>
                          <a href="tel: +420 725 404 979">725 404 979</a>
                        </li>
                        <li>
                          <i className="icon-mail-line-2"></i>
                          <a
                            target="_blank"
                            href="mailto:skola.javornik@seznam.cz"
                            rel="noreferrer"
                          >
                            skola.javornik@seznam.cz
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
              <div className="shape-image shape-image-1">
                <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-2">
                <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area copyright-default">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <p>
                    Copyright 2023 <a href="/">ZŠ a MŠ Javorník</a> | Design:{' '}
                    <a href="https://themeforest.net/user/devsvibe">DevsVibe</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollTopButton />
    </>
  );
};

export default Footer;
