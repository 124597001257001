import PropTypes from 'prop-types';
import React from 'react';

const SEO = ({ title }) => {
  return (
    <>
      <meta charset="utf-8" />
      <meta http-equiv="x-ua-compatible" content="ie=edge" />
      <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="%PUBLIC_URL%/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="%PUBLIC_URL%/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="%PUBLIC_URL%/favicon-16x16.png"
      />
      <link rel="manifest" href="%PUBLIC_URL%/site.webmanifest" />
      <link
        rel="mask-icon"
        href="%PUBLIC_URL%/safari-pinned-tab.svg"
        color="#5bbad5"
      />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta
        name="description"
        content="Základní a Mateřská škola Javorník ležící nedaleko Svitav"
      />
      <meta name="keywords" content="ZŠ a MŠ Javorník | Svitavy" />
      <meta http-equiv="content-language" content="cs" />
      <meta name="robots" content="all,follow" />

      <title>{title} || ZŠ a MŠ Javorník Svitavy</title>
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
