import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../common/SectionTitle';

const MsVice = () => {
  return (
    <div className="border-bottom-1 edu-choose-us-area-one  choose-us-style-1 edu-section-gap bg-color-white">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6">
            <div className="thumbnail">
              <img src="/images/materska-skola/8.webp" alt="Choose Us Images" />
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 offset-xl-1">
            <div className="inner  mt_md--40 mt_sm--40">
              <SectionTitle
                classes="text-start"
                slogan="Více"
                title="U nás najdete:"
                animateOnce={true}
              />

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                initiallyVisible
                animateOnce={true}
              >
                <div className="line-before">
                  <ul>
                    <li>útulné, příjemné prostředí</li>
                    <li>klidnou a přátelskou atmosféru</li>
                    <li>kvalifikovanost a kvalitu</li>
                    <li>respektování osobnosti dítěte</li>
                    <li>vedení k samostatnosti a odpovědnosti</li>
                  </ul>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MsVice;
