import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

const images = [
  '/images/home/1.webp',
  '/images/home/2.webp',
  '/images/home/3.webp',
  '/images/home/4.webp',
  '/images/home/5.webp',
];

<div className="col-lg-6 order-1 order-lg-2">
  <div className="banner-image">
    <div className="thumbnail">
      <img
        src="/images/zakladni-skola/1.webp"
        alt="Banner Images"
        style={{ width: '500px', height: '500px' }}
      />
    </div>
  </div>
</div>;

const BannerFour = () => {
  const [index, setIndex] = useState(0);
  const [loaded, setLoaded] = useState(false);

  // Preload next image
  useEffect(() => {
    const nextImage = new Image();
    nextImage.src = images[(index + 1) % images.length];
    nextImage.onload = () => setLoaded(true);
  }, [index]);

  // Transition to next image after preload
  useEffect(() => {
    if (loaded) {
      const interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % images.length);
        setLoaded(false); // Reset for next image preload
      }, 3000); // Adjust time as needed

      return () => clearInterval(interval);
    }
  }, [index, loaded]);

  return (
    <div className="slider-area banner-style-4 bg-image">
      <div className="wrapper d-flex align-items-center home-four-banner-wrapper">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="inner mt_md--10 mt_sm--10">
                <div className="content text-start">
                  <p className="pre-title">Vítejte na stránkách</p>
                  <img
                    className="logo-light"
                    src="/images/logo/logo.png"
                    alt="Main Logo"
                  />

                  <p className="description">
                    Základní a Mateřská škola Javorník ležící nedaleko Svitav
                  </p>
                  <div className="row gap-4">
                    <div className="col-12 col-md-6">
                      <Link
                        to="/aktuality"
                        className="d-flex items-center gap-3 align-items-center"
                      >
                        <span>Aktuality</span>
                        <i className="icon-arrow-right-line-right"></i>
                      </Link>
                    </div>

                    <div className="col-12 col-md-6">
                      <Link
                        to="/zs/onas"
                        className="d-flex items-center gap-3 align-items-center"
                      >
                        <span>Informace o Základní škole</span>
                        <i className="icon-arrow-right-line-right"></i>
                      </Link>
                    </div>

                    <div className="col-12 col-md-6">
                      <Link
                        to="/ms/onas"
                        className="d-flex items-center gap-3 align-items-center"
                      >
                        <span>Informace o Mateřské škole</span>
                        <i className="icon-arrow-right-line-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="banner-image">
                <div className="thumbnail">
                  <SwitchTransition mode="out-in">
                    <CSSTransition
                      key={images[index]}
                      addEndListener={(node, done) =>
                        node.addEventListener('transitionend', done, false)
                      }
                      classNames="fade"
                      timeout={3000} // Ensure this matches the CSS
                    >
                      <img
                        src={images[index]}
                        alt={`Slide ${index}`}
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </CSSTransition>
                  </SwitchTransition>
                </div>
              </div>
            </div>
          </div>

          <ul className="social-text-share">
            <li>
              <a
                href="https://m.facebook.com/people/Z%C5%A0-a-M%C5%A0-Javorn%C3%ADk/100051851865278/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Facebook
              </a>
            </li>
          </ul>

          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src="/images/shapes/shape-05-03.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-2">
              <img src="/images/shapes/shape-05-04.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-3">
              <img src="/images/shapes/shape-19-04.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-4">
              <img src="/images/shapes/shape-01-04.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-5">
              <img src="/images/shapes/shape-01-03.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-6">
              <img src="/images/shapes/shape-06.png" alt="Shape Thumb" />
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image shape-image-left">
            <img src="/images/shapes/shape-13-08.png" alt="Shape Thumb" />
          </div>
          <div className="shape-image shape-image-right">
            <img src="/images/shapes/shape-13-09.png" alt="Shape Thumb" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerFour;
