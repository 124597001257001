import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../common/SectionTitle';

const ZsVice = () => {
  return (
    <div className="border-bottom-1 edu-choose-us-area-one  choose-us-style-1 edu-section-gap bg-color-white">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6">
            <div className="thumbnail pt-6">
              <img
                src="/images/zakladni-skola/6.webp"
                alt="Choose Us Images"
                style={{ paddingTop: '10rem' }}
              />
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 offset-xl-1">
            <div className="inner  mt_md--40 mt_sm--40">
              <SectionTitle
                classes="text-start"
                slogan="Více"
                title="Co můžeme nabídnout"
              />

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                initiallyVisible
              >
                <div className="line-before">
                  <ul>
                    <li>menší počet žáků ve třídách</li>
                    <li>nadstandartní personální zajištění</li>
                    <li>
                      dobrá dostupnost autobusové dopravy ze Svitav i Litomyšle
                    </li>
                    <li>provoz školy 6:40 – 16:00, ve středu do 16:30</li>
                    <li>systém týdenních plánů</li>
                    <li>
                      klasické hodnocení známkami doplněné dvakrát ročně slovním
                      hodnocením
                    </li>
                    <li>vlastní jídelna s kuchyní v budově MŠ</li>
                    <li>aktivní trávení přestávek ve škole i venku</li>
                    <li>velká školní zahrada</li>
                    <li>
                      akce - pobytový adaptační program na začátku školního
                      roku, společné odpolední akce s MŠ, (svatomartinský
                      průvod, adventní a velikonoční dílny, Vánoce, čarodějnice,
                      Den dětí), spaní ve škole, vícedenní školní výlet
                    </li>
                    <li>
                      spolupráce s obcí – rozsvěcení vánočního stromu, pomoc s
                      úklidem obce
                    </li>
                  </ul>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZsVice;
