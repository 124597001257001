import React from 'react';
import SEO from '../../common/SEO';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import HeaderOne from '../../common/header/HeaderOne';
import Footer from '../common/Footer';
import MsJak from './MsJak';
import MsONas from './MsONas';
import MsVice from './MsVice';

const MsMain = () => {
  return (
    <>
      <SEO title="Mateřská škola - O Nás" />

      <HeaderOne />

      <BreadcrumbOne
        title="Základní škola - O Nás"
        rootUrl="/"
        parentUrl="Domů"
        currentUrl="Základní škola - O Nás"
      />

      <MsONas />

      <MsVice />

      <MsJak />

      <Footer />
    </>
  );
};

export default MsMain;
